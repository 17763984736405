<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <el-button size="mini" @click="handelExport" icon="el-icon-download"
                           class="hidden-sm-only hidden-sm-only" v-has="'facility_export'">导 出
                </el-button>
                <el-button size="mini" @click="handelAdd" icon="el-icon-plus"
                           class="hidden-sm-only hidden-sm-only" v-has="'create_facility'">添 加
                </el-button>
                <el-button size="mini" @click="getData" icon="el-icon-refresh"
                           class="hidden-sm-only hidden-sm-only">刷 新
                </el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="健康设施"/>
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small">
                <el-form-item label="名称">
                    <el-input v-model="search.name" placeholder="请输入二级单位" clearable/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData" icon="el-icon-search" v-has="'facility_search'">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" stripe size="small" v-loading="loading" width="100%" height="100%"
                          :summary-method="getSummaries" show-summary border :key="forceUpdateKey">
                    <el-table-column type="index" label="序号" align="center"
                                     :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                     width="55"/>
                    <el-table-column align="center" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title"
                                     :width="v.width" show-overflow-tooltip :key="idx" sortable>
                    </el-table-column>
                    <el-table-column align="center" width="180" label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="text"  size="mini" @click="handleUpdate(scope.row)" v-has="'facility_edit'">
                                编辑详情
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes"
                           :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
        </div>
        <el-dialog :visible.sync="visible" width="50%" class="dialog" title="添加/修改">
            <el-form ref="form" :model="form" v-loading="loading" label-position="left" size="mini">
                <facility-form :formData="tab1data" :form="form" labelwidth="0"
                               style="height: calc( 100vh - 400px );overflow-y: auto;padding: 10px"></facility-form>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="visible=false" size="mini">取 消</el-button>
                <el-button type="primary" size="mini" @click="formSubmit" :loading="loading">确 认</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import facilityForm from './myform'
    import tab1data from "./data";

    export default {
        components: {
            facilityForm,
        },
        data() {
            return {
                loading: false,
                search: {},
                table: tab1data.table,
                tab1data: tab1data.tab1form,
                dialogVisible: false,
                form: {},
                isFullIdCard: [],
                forceUpdateKey:0,
                visible: false
            }
        },
        methods: {
            handelAdd(){
                this.form = {}
                this.visible = true
            },
            getSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    if (index === 1) {
                        sums[index] = '';
                        return;
                    }
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                        sums[index] += ' ';
                    } else {
                        sums[index] = '';
                    }
                });
                console.log(sums)
                return sums;
            },
            handelExport() {
                window.open(`/api/company/facility/export`, '_blank');
                // try {
                //     this.loading = true; // 假设你有一个loading状态表示正在下载
                //     this.$http({
                //         url: `/company/facility/export`,
                //         method: 'GET',
                //         responseType: 'blob', // 重要：确保响应类型为blob
                //     }).then(response => {
                //         this.loading = false;
                //         const link = document.createElement('a');
                //         let blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                //         link.style.display = 'none';
                //         link.href = URL.createObjectURL(blob);//创建url对象
                //         link.download = response.headers['content-disposition'].substr(20); //下载后文件名
                //         document.body.appendChild(link);
                //         link.click();
                //         document.body.removeChild(link);
                //         URL.revokeObjectURL(link.href);
                //     }).catch((error) => {
                //         console.error('Download error:', error);
                //         alert('文件下载失败');
                //     });
                // } catch (error) {
                //     this.loading = false
                //     this.$message.error(error.message)
                // }
            },
            formSubmit() {
                this.$confirm(`确认要提交的记录吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$http.post(`/company/facility`,this.form)
                        .then(resp => {
                            this.$message[resp.data.status](resp.data.msg)
                            if (resp.data.status == 'success') this.getData()
                        }).catch(err => {
                        this.$message.error(err.message)
                    }).finally(() => {
                        this.loading = false
                    })
                    this.loading = false
                }).catch(() => {
                });
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            handleUpdate(row) {
                this.form = row
                this.visible = true
            },
            async getData() {
                this.visible = false
                this.loading = true
                try {
                    this.$http.get(`/company/facility`, {params: this.search})
                        .then(resp => {
                            this.table.data = resp.data.data
                            this.forceUpdateKey += 1;
                            this.loading = false
                        }).catch(err => {
                        console.log(err)
                        this.loading = false
                        this.$message.error(err.message)
                    })
                } catch (error) {
                    this.loading = false
                    this.$message.error(error.message)
                }
            },
            // async getDataView() {
            //     this.loading = true
            //     try {
            //         this.$http.get(`/company/facility/view`)
            //             .then(resp => {
            //                 this.form = resp.data.data || {}
            //                 this.loading = false
            //             }).catch(err => {
            //             this.loading = false
            //             this.$message.error(err.message)
            //         })
            //     } catch (error) {
            //         this.loading = false
            //         this.$message.error(error.message)
            //     }
            // }
        },
        mounted() {
            // if(this.$store.getters.userType=='1'){
            this.getData()
            // }
            // if(this.$store.getters.userType=='2'){
            //     this.getDataView()
            // }
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 0 !important;
            margin-bottom: 0 !important;
        }
    }

    .main-content {
        flex: 1;
        position: relative;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .pager {
        padding-top: 10px;
        text-align: center;
    }
</style>
