<template>
    <div>
        <el-row :gutter="20">
            <div>
                <el-col :span="item.span?item.span:24" v-for="(item, index) in formData" :key="index">
                    <div v-if="item.type=='title'" style="font-weight: 700;padding:10px 0;">{{item.title}}</div>
                    <el-form-item :label="item.title" v-else
                                  :prop="item.required?item.name:''"
                                  :rules="item.required?[{ required: true, message: '必填项',trigger: 'change'}]:[]">
                        <el-date-picker
                                v-model="form[item.name]"
                                v-if="item.type==='date'"
                                value-format="yyyy-MM-dd"
                                style="width: 100%"
                                :placeholder="item.placeholder">
                        </el-date-picker>
                        <el-radio-group
                                v-model="form[item.name]"
                                v-if="item.type==='radio'"
                        >
                            <el-radio :label="iteme.value" :key="iteme.value"
                                      v-for="iteme in item.options">{{iteme.label}}
                            </el-radio>
                        </el-radio-group>
                        <el-input
                                :disabled="item.disabled"
                                autocomplete="off"
                                v-model="form[item.name]"
                                v-else-if="item.type==='input'"
                                :placeholder="item.placeholder">
                            <template v-if="item.slots" slot="append">{{ item.slots }}<sup v-if="item.sup">{{
                                item.sup
                                }}</sup></template>
                        </el-input>
                        <el-input
                                :disabled="item.disabled"
                                v-model="form[item.name]"
                                autocomplete="off"
                                type="hidden"
                                v-else-if="item.type==='hidden'"
                                :placeholder="item.placeholder">
                            <template v-if="item.slots" slot="append">{{ item.slots }}</template>
                        </el-input>
                        <el-input
                                :disabled="item.disabled"
                                v-model="form[item.name]"
                                autocomplete="off"
                                type="number"
                                v-else-if="item.type==='number'"
                                :placeholder="item.placeholder">
                            <template v-if="item.slots" slot="append">{{ item.slots }}<sup v-if="item.sup">{{
                                item.sup
                                }}</sup></template>
                        </el-input>
                        <el-input
                                :disabled="item.disabled"
                                v-model="form[item.name]"
                                autocomplete="off"
                                type="number"
                                v-else-if="item.type==='number'"
                                :placeholder="item.placeholder">
                            <template v-if="item.slots" slot="append">{{ item.slots }}<sup v-if="item.sup">{{
                                item.sup
                                }}</sup></template>
                        </el-input>
                        <el-input
                                v-else-if="item.type==='textarea'"
                                type="textarea"
                                :disabled="item.disabled"
                                :maxlength="item.maxlength"
                                show-word-limit
                                :rows="item.rows"
                                v-model="form[item.name]"
                                :placeholder="item.placeholder"
                        >
                        </el-input>
                        <el-checkbox
                                v-else-if="item.type==='checkbox'"
                                v-model="form[item.name]"
                        >
                            {{ item.text }}
                        </el-checkbox>
                        <el-select
                                v-model="form[item.name]"
                                v-else-if="item.type==='multiple'"
                                style="width: 100%"
                                filterable
                                multiple
                                :placeholder="item.placeholder"
                        >
                            <el-option
                                    v-for="o in item.options"
                                    :key="o.id"
                                    :label="o[item.label]"
                                    :value="o[item.key]"
                            >
                            </el-option>
                        </el-select>
                        <el-select
                                v-model="form[item.name]"
                                v-else-if="item.type==='select'"
                                style="width: 100%"
                                filterable
                                :placeholder="item.placeholder"
                        >
                            <el-option
                                    v-for="o in item.options"
                                    :key="o.id"
                                    :label="o[item.label]"
                                    :value="o[item.key]"
                            >
                            </el-option>
                        </el-select>
                        <el-select
                                v-else-if="item.type==='select-remote-multiple'"
                                v-model="form[item.name]"
                                collapse-tags
                                multiple
                                filterable
                                remote
                                reserve-keyword
                                placeholder="请输入关键词"
                                @change="handleSelect(item.key,$event)"
                                :remote-method="remoteMethod"
                                style="width:100%"
                        >
                            <el-option
                                    v-for="o in item.option"
                                    :key="o.name"
                                    :label="o.name"
                                    :value="o.name"
                            >
                                <span style="float: left">{{ o.name }}</span>
                                <span style="float: right; color: #8492a6; font-size: 13px">{{ o.type }}</span>
                            </el-option>
                        </el-select>
                        <el-select
                                v-else-if="item.type==='select-allow-create'"
                                allow-create
                                filterable
                                v-model="form[item.name]"
                        >
                            <el-option
                                    v-for="o in item.options"
                                    :key="o.value"
                                    :label="o.label"
                                    :value="o.value"
                            >
                            </el-option>
                        </el-select>
                        <span v-else>
                            <span></span>
                        </span>
                    </el-form-item>
                </el-col>
            </div>
        </el-row>
    </div>
</template>
<script>
    export default {
        name: 'facilityForm',
        props: {
            formData: {
                default: []
            },
            labelwidth: {
                type: String,
                default: "120px"
            },
            form: {
                default: {}
            },
            btnBgColor: {
                type: String,
                default: "#9089fa"
            },
            btnColor: {
                type: String,
                default: "#ffffff"
            },

        },
        data() {
            return {
                loading: false,
            }
        },
        methods: {},
    };
</script>
<style scoped>

    /deep/ .el-radio-button__inner, .el-radio-group {
        display: inline-block;
        vertical-align: middle;
    }
</style>
