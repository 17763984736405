const data = {
    tab1form: [
        // {
        //     "name": "company",
        //     "type": "text",
        //     "title": "二级单位",
        //     "required": true,
        //     "placeholder": "请输入二级单位名称",
        //     "value": "",
        //     "span": 12,
        //     "disabled": false
        // },
        {
            "name": "health_home",
            "type": "number",
            "title": "健康之家的数量",
            "required": false,
            "placeholder": "请输入健康之家的数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "health_cabin",
            "type": "number",
            "title": "健康小屋的数量",
            "required": false,
            "placeholder": "请输入健康小屋的数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "health_backpack",
            "type": "number",
            "title": "健康背包的数量",
            "required": false,
            "placeholder": "请输入健康背包的数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "health_corner",
            "type": "number",
            "title": "健康角的数量",
            "required": false,
            "placeholder": "请输入健康角的数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "aed",
            "type": "number",
            "title": "自动体外除颤器(AED)的数量",
            "required": false,
            "placeholder": "请输入AED的数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "first_aid_kit",
            "type": "number",
            "title": "急救包的数量",
            "required": false,
            "placeholder": "请输入急救包的数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "psychology_counseling_room",
            "type": "number",
            "title": "心理辅导室的数量",
            "required": false,
            "placeholder": "请输入心理辅导室的数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "nutrition_health_canteen",
            "type": "number",
            "title": "营养健康食堂的数量",
            "required": false,
            "placeholder": "请输入营养健康食堂的数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "smoke_free_workplace",
            "type": "number",
            "title": "无烟工作场所的数量",
            "required": false,
            "placeholder": "请输入无烟工作场所的数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "health_manager_equipped",
            "type": "number",
            "title": "配备的健康管理师数量",
            "required": false,
            "placeholder": "请输入配备的健康管理师数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "health_manager_trained",
            "type": "number",
            "title": "接受培训的健康管理师数量",
            "required": false,
            "placeholder": "请输入接受培训的健康管理师数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "health_administrator_equipped",
            "type": "number",
            "title": "配备的健康管理员数量",
            "required": false,
            "placeholder": "请输入配备的健康管理员数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "health_administrator_trained",
            "type": "number",
            "title": "接受培训的健康管理员数量",
            "required": false,
            "placeholder": "请输入接受培训的健康管理员数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "health_administrator_assessed",
            "type": "number",
            "title": "进行考核的健康管理员数量",
            "required": false,
            "placeholder": "请输入进行考核的健康管理员数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "resident_medical_staff",
            "type": "number",
            "title": "驻厂医护人员的数量",
            "required": false,
            "placeholder": "请输入驻厂医护人员的数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "red_cross_rescuer_trained",
            "type": "number",
            "title": "接受培训的红十字救护员数量",
            "required": false,
            "placeholder": "请输入接受培训的红十字救护员数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "dynamic_ecg",
            "type": "number",
            "title": "动态心电仪的数量",
            "required": false,
            "placeholder": "请输入动态心电仪的数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "oxygen_inhaler",
            "type": "number",
            "title": "吸氧机的数量",
            "required": false,
            "placeholder": "请输入吸氧机的数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "smart_blood_pressure_monitor",
            "type": "number",
            "title": "智能血压计的数量",
            "required": false,
            "placeholder": "请输入智能血压计的数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "smart_blood_glucose_meter",
            "type": "number",
            "title": "智能血糖仪的数量",
            "required": false,
            "placeholder": "请输入智能血糖仪的数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "waist_measuring_tape",
            "type": "number",
            "title": "腰围测量尺的数量",
            "required": false,
            "placeholder": "请输入腰围测量尺的数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "height_weight_scale",
            "type": "number",
            "title": "身高体重秤的数量",
            "required": false,
            "placeholder": "请输入身高体重秤的数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "fascia_gun",
            "type": "number",
            "title": "筋膜枪的数量",
            "required": false,
            "placeholder": "请输入筋膜枪的数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "spectrum_therapy_device",
            "type": "number",
            "title": "频谱治疗仪的数量",
            "required": false,
            "placeholder": "请输入频谱治疗仪的数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "red_cross_rescuer_certified",
            "type": "number",
            "title": "取得证书的红十字救护员数量",
            "required": false,
            "placeholder": "请输入取得证书的红十字救护员数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "psychological_counselor_certified",
            "type": "number",
            "title": "取得证书的心理咨询师数量",
            "required": false,
            "placeholder": "请输入取得证书的心理咨询师数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "public_nutritionist_certified",
            "type": "number",
            "title": "取得证书的公共营养师数量",
            "required": false,
            "placeholder": "请输入取得证书的公共营养师数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "various_ball_courts",
            "type": "number",
            "title": "各种球类场馆的数量",
            "required": false,
            "placeholder": "请输入各种球类场馆的数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "sports_track_stadium",
            "type": "number",
            "title": "运动步道和体育场的数量",
            "required": false,
            "placeholder": "请输入运动步道和体育场的数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "yoga_dance_fitness_room",
            "type": "number",
            "title": "瑜伽室、舞蹈室和健身室的数量",
            "required": false,
            "placeholder": "请输入瑜伽室、舞蹈室和健身室的数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "reading_room",
            "type": "number",
            "title": "图书室和阅览室的数量",
            "required": false,
            "placeholder": "请输入图书室和阅览室的数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "laundry_room",
            "type": "number",
            "title": "洗衣房的数量",
            "required": false,
            "placeholder": "请输入洗衣房的数量",
            "value": "",
            "span": 12,
            "disabled": false
        },
        {
            "name": "female_worker_health_room",
            "type": "number",
            "title": "女工保健室的数量",
            "required": false,
            "placeholder": "请输入女工保健室的数量",
            "value": "",
            "span": 12,
            "disabled": false
        }
    ],
    table: {
        columns: [
            {title: '二级单位', field: 'company', width: '200'},
            {title: '创建时间', field: 'created_at', width: '200'},
            {title: '健康之家的数量', field: 'health_home', width: '200'},
            {title: '健康小屋的数量', field: 'health_cabin', width: '200'},
            {title: '健康背包的数量', field: 'health_backpack', width: '200'},
            {title: '健康角的数量', field: 'health_corner', width: '200'},
            {title: '自动体外除颤器(AED)的数量', field: 'aed', width: '200'},
            {title: '急救包的数量', field: 'first_aid_kit', width: '200'},
            {title: '心理辅导室的数量', field: 'psychology_counseling_room', width: '200'},
            {title: '营养健康食堂的数量', field: 'nutrition_health_canteen', width: '200'},
            {title: '无烟工作场所的数量', field: 'smoke_free_workplace', width: '200'},
            {title: '配备的健康管理师数量', field: 'health_manager_equipped', width: '200'},
            {title: '接受培训的健康管理师数量', field: 'health_manager_trained', width: '200'},
            {title: '配备的健康管理员数量', field: 'health_administrator_equipped', width: '200'},
            {title: '接受培训的健康管理员数量', field: 'health_administrator_trained', width: '200'},
            {title: '进行考核的健康管理员数量', field: 'health_administrator_assessed', width: '200'},
            {title: '驻厂医护人员的数量', field: 'resident_medical_staff', width: '200'},
            {title: '接受培训的红十字救护员数量', field: 'red_cross_rescuer_trained', width: '220'},
            {title: '动态心电仪的数量', field: 'dynamic_ecg', width: '200'},
            {title: '吸氧机的数量', field: 'oxygen_inhaler', width: '200'},
            {title: '智能血压计的数量', field: 'smart_blood_pressure_monitor', width: '200'},
            {title: '智能血糖仪的数量', field: 'smart_blood_glucose_meter', width: '200'},
            {title: '腰围测量尺的数量', field: 'waist_measuring_tape', width: '200'},
            {title: '身高体重秤的数量', field: 'height_weight_scale', width: '200'},
            {title: '筋膜枪的数量', field: 'fascia_gun', width: '200'},
            {title: '频谱治疗仪的数量', field: 'spectrum_therapy_device', width: '200'},
            {title: '取得证书的红十字救护员数量', field: 'red_cross_rescuer_certified', width: '230'},
            {title: '取得证书的心理咨询师数量', field: 'psychological_counselor_certified', width: '200'},
            {title: '取得证书的公共营养师数量', field: 'public_nutritionist_certified', width: '200'},
            {title: '各种球类场馆的数量', field: 'various_ball_courts', width: '200'},
            {title: '运动步道和体育场的数量', field: 'sports_track_stadium', width: '200'},
            {title: '瑜伽室、舞蹈室和健身室的数量', field: 'yoga_dance_fitness_room', width: '240'},
            {title: '图书室和阅览室的数量', field: 'reading_room', width: '200'},
            {title: '洗衣房的数量', field: 'laundry_room', width: '200'},
            {title: '女工保健室的数量', field: 'female_worker_health_room', width: '200'}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    },
}
export default data
